import PaginationComponent from '@components/pagination'
import { useWindowDimensions } from '@services/hooks'
import React, { useState } from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import './style.scss'
import { Questions } from './types'

const QuestionsSection = () => {
  const [index, setIndex] = useState(0)
  const { width } = useWindowDimensions()
  const lgWindow = width >= 992
  const smWindow = width <= 992

  const handleSelect = (selectedIndex, e) => setIndex(selectedIndex)

  return (
    <>
      <Row className={'mt-4'}>
        <Col
          lg={{ span: 5, order: 1 }}
          md={{ span: 12, order: 2 }}
          sm={{ order: 2 }}
          xs={{ order: 2 }}
          className="d-flex flex-column justify-content-center"
        >
          {Questions.map(({ type, icon }, thisIndex) => (
            <div
              className={`d-flex mb-2 question-item ${
                index === thisIndex && 'active'
              }`}
              onClick={_ => setIndex(thisIndex)}
              key={type}
            >
              <div className={`position-relative ${smWindow && 'd-none'}`}>
                <div className="tint position-absolute" />
                <img src={icon} width={15} />
              </div>

              <p
                className={`mb-0 ms-2 fw-bolder ${smWindow && 'fs-5'}`}
                style={{ letterSpacing: 0.5 }}
              >
                {type.toUpperCase()}
              </p>
            </div>
          ))}
        </Col>

        <Col
          lg={7}
          md={{ span: 12, order: 1 }}
          sm={{ span: 12, order: 1 }}
          xs={{ span: 12, order: 1 }}
        >
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            fade
            interval={smWindow ? 5000 : 50000000}
            controls={!lgWindow}
            variant="dark"
            indicators={false}
          >
            {Questions.map(({ image, type }) => (
              <Carousel.Item key={type}>
                <img className="d-block w-100" src={image} alt="First slide" />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>

      <Row>
        <Col className="pagination-wrapper">
          <PaginationComponent
            list={Questions}
            selected={index}
            setSelected={setIndex}
          />
        </Col>
      </Row>
    </>
  )
}
export default QuestionsSection
