import ArrowLeft from '@static/images/our-platform/icons/arrow-left.svg'
import ArrowRight from '@static/images/our-platform/icons/arrow-right.svg'
import React, { Dispatch, SetStateAction } from 'react'
import { Pagination } from 'react-bootstrap'
import './style.scss'

interface props {
  list: any[]
  selected: number
  setSelected: Dispatch<SetStateAction<number>>
}

const PaginationComponent = ({ list, selected, setSelected }: props) => {
  return (
    <Pagination className="justify-content-between d-flex align-items-end">
      <div className="d-flex">
        {list.map((i, thisIndex) => (
          <Pagination.Item
            key={thisIndex}
            active={selected === thisIndex}
            onClick={_ => setSelected(thisIndex)}
            className="me-2"
          />
        ))}
      </div>

      <div className="d-flex me-3">
        <Pagination.Item
          className="me-1 arrow-button"
          disabled={selected === 0}
          onClick={_ => setSelected(selected - 1)}
        >
          <img src={ArrowLeft} alt="arrow-left" className="m-1" />
        </Pagination.Item>
        <Pagination.Item
          className="arrow-button"
          disabled={selected === list.length - 1}
          onClick={_ => setSelected(selected + 1)}
        >
          <img src={ArrowRight} alt="arrow-right" className="m-1" />
        </Pagination.Item>
      </div>
    </Pagination>
  )
}

export default PaginationComponent
