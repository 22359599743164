import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './style.scss'

interface props {
  title: string
  image: string
  imageWidth?: any
}

const HeaderAlternative = ({ title, image, imageWidth }: props) => {
  return (
    <div className="hero-alternative">
      <Container className="pt-3 pb-5">
        <Row className="h-100 d-flex align-items-center">
          <Col
            lg={6}
            md={12}
            className="d-flex d-lg-block justify-content-center"
          >
            <h1 className={'text-white mb-lg-3'} style={{ fontSize: 60 }}>
              {title}
            </h1>
          </Col>
          <Col lg={6} md={0}>
            <img
              className={`w-75 m-auto d-none d-lg-block`}
              src={image}
              style={{ width: imageWidth }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HeaderAlternative
