import BaseContainer from '@components/base-container'
import HeaderAlternative from '@components/hero-alternative'
import { useWindowDimensions } from '@services/hooks'
import { Color } from '@services/styles'
import ExperienceImage from '@static/images/our-platform/experience.png'

import HeroImage from '@static/images/our-platform/hero.png'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import QuestionsSection from './questions-section'
import './style.scss'
import { CreatorFeatures } from './types'

const pageColors = {
  backgroundColor: '#F9F9F9',
  titleColor: '#4C68AB'
}

const OurPlatform = () => {
  const { width } = useWindowDimensions()
  return (
    <BaseContainer withPadding>
      <div style={{ backgroundColor: pageColors.backgroundColor }}>
        <HeaderAlternative title={'Our Platform'} image={HeroImage} />
      </div>

      <div style={{ backgroundColor: pageColors.backgroundColor }}>
        <Container>
          <Row className="py-4 h-100 d-flex align-items-center">
            <Col lg={6} className="d-flex justify-content-center">
              <img src={ExperienceImage} style={{ width: '60%' }} />
            </Col>
            <Col lg={6}>
              <p
                className="fs-5 fw-light pe-lg-5 text-center text-lg-start mt-3 mt-lg-0"
                style={{ color: '#1E2D4F' }}
              >
                Create a mobile survey in minutes, with features and question
                types specifically crafted to address the most common research
                objectives out there.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div style={{ backgroundColor: Color.WHITE }}>
        <Container>
          <Row className="h-100 d-flex align-items-center">
            <Col lg={{ span: 6, order: 2 }}>
              <Row>
                {CreatorFeatures.map(
                  ({ text, image, borderClasses }, index) => (
                    <Col
                      xs={6}
                      className={`d-flex align-items-center justify-content-center' +
                      ' flex-column p-4 border-light ${borderClasses}`}
                      key={index}
                    >
                      <img src={image} width={60} className={'mb-2'} />
                      <p className={'text-center'}>{text}</p>
                    </Col>
                  )
                )}
              </Row>
            </Col>

            <Col lg={{ span: 6, order: 1 }}>
              <p
                className="fs-5 fw-light pt-3 pb-4 py-lg-0 pe-lg-5 text-center text-lg-start"
                style={{ color: '#1E2D4F' }}
              >
                Our mobile survey creator makes it easy to create high quality,
                responsive and unbiased surveys for any research need. Our
                frictionless tool allows you to create surveys quickly, and our
                experts are on hand to help with any issues.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        style={{ backgroundColor: pageColors.backgroundColor }}
        className="py-5"
      >
        <Container>
          <h2
            style={{ color: pageColors.titleColor }}
            className={'text-center fw-bold '}
          >
            Questions you can create
          </h2>
          <p className={'fw-light text-center'}>
            You can use multidimensional question types to further explore
            customer's responses and gain new insights into your data.
          </p>
          <QuestionsSection />
        </Container>
      </div>
    </BaseContainer>
  )
}

export default OurPlatform
