import EasyToBuildImage from '@static/images/our-platform/abc.svg'
import ForAnyResearchImage from '@static/images/our-platform/pie-chart.svg'
import DescriptionImage from '@static/images/our-platform/questions/description.png'
import DescriptionIcon from '@static/images/our-platform/questions/description.svg'
import MatrixMultipleImage from '@static/images/our-platform/questions/matrix-multiple.png'
import MatrixMultipleIcon from '@static/images/our-platform/questions/matrix-multiple.svg'
import MatrixSingleImage from '@static/images/our-platform/questions/matrix-single.png'
import MatrixSingleIcon from '@static/images/our-platform/questions/matrix-single.svg'
import MultipleSelectionImage from '@static/images/our-platform/questions/multiple-selection.png'
import MultipleSelectionIcon from '@static/images/our-platform/questions/multiple-selection.svg'
import NumericImage from '@static/images/our-platform/questions/numeric.png'
import NumericIcon from '@static/images/our-platform/questions/numeric.svg'
import OpenEndedImage from '@static/images/our-platform/questions/open-ended.png'
import OpenEndedIcon from '@static/images/our-platform/questions/open-ended.svg'
import RankingImage from '@static/images/our-platform/questions/ranking.png'
import RankingIcon from '@static/images/our-platform/questions/ranking.svg'
import RatingStarsImage from '@static/images/our-platform/questions/rating-stars.png'
import RatingStarsIcon from '@static/images/our-platform/questions/rating-stars.svg'

import SingleSelectionImage from '@static/images/our-platform/questions/single-selection.png'

import SingleSelectionIcon from '@static/images/our-platform/questions/single-selection.svg'
import SliderImage from '@static/images/our-platform/questions/slider.png'
import SliderIcon from '@static/images/our-platform/questions/slider.svg'
import ExpertsHelpImage from '@static/images/our-platform/tech-support.svg'
import QuickToCreateImage from '@static/images/our-platform/time.svg'

export const CreatorFeatures = [
  {
    text: 'Easy to build',
    image: EasyToBuildImage,
    borderClasses: 'border'
  },
  {
    text: 'Quick to create',
    image: QuickToCreateImage,
    borderClasses: 'border-top border-end'
  },
  {
    text: 'Experts Helps',
    image: ExpertsHelpImage,
    borderClasses: 'border-bottom border-start'
  },
  {
    text: 'For any research',
    image: ForAnyResearchImage,
    borderClasses: 'border'
  }
]

export const Questions = [
  {
    type: 'Single Selection',
    image: SingleSelectionImage,
    icon: SingleSelectionIcon
  },
  {
    type: 'Multiple Selection',
    image: MultipleSelectionImage,
    icon: MultipleSelectionIcon
  },
  { type: 'Open Ended', image: OpenEndedImage, icon: OpenEndedIcon },
  { type: 'Numeric', image: NumericImage, icon: NumericIcon },
  { type: 'Description', image: DescriptionImage, icon: DescriptionIcon },
  { type: 'Rating Stars', image: RatingStarsImage, icon: RatingStarsIcon },
  { type: 'Slider', image: SliderImage, icon: SliderIcon },
  { type: 'Ranking', image: RankingImage, icon: RankingIcon },
  {
    type: 'Matrix Single Selection',
    image: MatrixSingleImage,
    icon: MatrixSingleIcon
  },
  {
    type: 'Matrix Multiple Selection',
    image: MatrixMultipleImage,
    icon: MatrixMultipleIcon
  }
]
